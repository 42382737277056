import React from 'react'
import "./mainPage.css"
import leftBanner from "../../images/leftBanner.png"
import mainTitle from "../../images/mainTitle.png"
import pictograms from "../../images/pictograms.png"
import desktopCar from "../../images/desktopCar.png"
import desktopFridge from "../../images/desktopFridge.png"
import desktopVoucher from "../../images/desktopVoucher.png"
import hatBag from "../../images/hatBag.png"
import mobilePictograms from "../../images/mobilePictograms.png"
import igIcon from "../../images/igIcon.svg"
import fbIcon from "../../images/fbIcon.svg"

function MainPage() {
    return (
        <div className="mainPage">
            <img src={leftBanner}
                className="leftBanner" />
            <img src={mainTitle}
                className="mainTitle" />
            <img src={pictograms}
                className="pictograms" />

            <div className="content">

                <div>
                    <img src={desktopCar} className="carImage" />
                    <div className="textHolder">
                        <div className="textItem">
                            <div>СВАКИХ 10 ДАНА</div>
                            <div>ТОЈОТА КОРОЛА</div>
                        </div>
                        <div className="textItem">
                            <div>СВАКИХ 5 ДАНА</div>
                            <div>ВАУЧЕР <br />
                                ОД 2000 ЕВРА <br />
                                ЗА КУПОВИНУ
                            </div>
                        </div>
                        <div className="textItem">
                            <div>СВАКОГ ДАНА</div>
                            <div>МИНИ ФРИЖИДЕР</div>
                        </div>
                        <div className="textItem">
                            <div>СВАКОГ САТА</div>
                            <div>ОЛИМПИЈСКА <br />
                                ОПРЕМА</div>
                        </div>
                    </div>
                </div>


                <div className="prizes">
                    <img src={desktopVoucher} className="voucherImage" />
                    <img src={desktopFridge} className="fridgeImage" />
                    <img src={hatBag} className="bagImage" />
                </div>

                <div className="desktopIcons">
                    <a href="https://www.instagram.com/zajecarsko.pivo/" target='_blank'>
                        <img src={igIcon} />
                    </a>

                    <a href="https://www.facebook.com/pivo.zajecarsko" target='_blank'>
                        <img src={fbIcon} />
                    </a>
                </div>

                <div className="lastTextWelcome">Слике награда не морају одговарати стварним наградама</div>

                <div className="mobileFooter">
                    <img src={mobilePictograms}
                        className="mobilePictograms" />
                    <div className='mobileMainTitle'>НЕКА ИГРЕ ПОЧНУ</div>

                    <div className="mobileIcons">
                        <a href="https://www.instagram.com/zajecarsko.pivo/" target='_blank'>
                            <img src={igIcon} />
                        </a>

                        <a href="https://www.facebook.com/pivo.zajecarsko" target='_blank'>
                            <img src={fbIcon} />
                        </a>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default MainPage

{/* */ }
