import React from 'react'
import "./header.css"
import { Link } from "react-router-dom";
import mainLogo from "../../images/mainLogo.png"

function Header() {
    return (
        <header className="header">
            <img src={mainLogo}
                className="mainLogo" />

            <div className="tabList">
                    <Link to="/">УЧЕСТВУЈ</Link>
                    <Link to="/">МЕХАНИЗАМ</Link>
                    <Link to="/">НАГРАДЕ</Link>
                    <Link to="/">ПРАВИЛА</Link>
                    <Link to="/">ПИТАЊА И ОДГОВОРИ</Link>
                    <Link to="/">ДОБИТНИЦИ</Link>
            </div>
        </header>
    )
}

export default Header