import React, { useState, useRef } from 'react'
import "./ageGate.css"
import moment from 'moment';

const AgeGate = props => {

    const [firstValue, setFirstValue] = useState('')
    const [secondValue, setSecondValue] = useState('')
    const [thirdValue, setThirdValue] = useState('')
    const secondInputRef = useRef()
    const thirdInputRef = useRef()

    const birthday = moment([thirdValue, secondValue - 1, firstValue])

    const submitAge = () =>{
        const age = moment().diff(birthday, 'years')

        if(age >= 18  && age <= 124){
            localStorage.setItem("ageGate", "true")
            props.handleAgeGate("true")
        }
    }
    

    const handleFirstChange = (event) => {
        let input = event.target.value;
        // Allow only numeric characters (0-9)
        input = input.replace(/[^0-9]/g, '');
        // Limit input to 2 characters
        input = input.slice(0, 2);

        // Convert input to a number
        const number = parseInt(input, 10);
        if (input === '' || (!isNaN(number) && number <= 31)) {
            setFirstValue(input);
            // Focus on the second input field
            if (input.length === 2) {
                secondInputRef.current.focus();
            }
        }
    }

    const handleSecondChange = (event) => {
        let input = event.target.value;
        // Allow only numeric characters (0-9)
        input = input.replace(/[^0-9]/g, '');
        // Limit input to 2 characters
        input = input.slice(0, 2);

        // Convert input to a number
        const number = parseInt(input, 10);
        if (input === '' || (!isNaN(number) && number <= 12)) {
            setSecondValue(input);
            // Focus on the second input field
            if (input.length === 2) {
                thirdInputRef.current.focus();
            }
        }
    }

    const handleThirdChange = (event) => {
        let input = event.target.value;
        // Allow only numeric characters (0-9)
        input = input.replace(/[^0-9]/g, '');
        // Limit input to 2 characters
        input = input.slice(0, 4);

        // Convert input to a number
        const number = parseInt(input, 10);
        if (input === '' || (!isNaN(number) && number <= 2023)) {
            setThirdValue(input);
        }
    }

    return (
        <div className="ageGate">
            <div className="gateTitle">
                Унеси датум свог рођења:
            </div>

            <div className="gateInputs">
                <input placeholder="Дан"
                    value={firstValue}
                    onChange={handleFirstChange} />

                <input placeholder="Месец"
                    value={secondValue}
                    onChange={handleSecondChange}
                    ref={secondInputRef} />

                <input placeholder="Година"
                    ref={thirdInputRef}
                    value={thirdValue}
                    onChange={handleThirdChange} />

            </div>

            <div className="gateButton"
                 onClick={submitAge}>
                ПОШАЉИ
            </div>
        </div>
    )
}

export default AgeGate