import './App.css';
import React, { useState } from 'react'
import MainPage from './components/mainPage/mainPage';
import Header from './components/header/header';
import AgeGate from './components/ageGate/ageGate';

function App() {

  const [ageGate, setAgeGate] = useState(localStorage.getItem("ageGate") || "false")
  const handleAgeGate = (value) => setAgeGate(value)

  console.log('Uskoro')
  return (
    <div className="App">

      {ageGate === "true" &&
        <div className="soonStarting">

          <div className="soonMain">
            <div className="final-text">Наградна игра</div>
            <div className="final-center">„НЕКА ИГРЕ ПОЧНУ”</div>
            <div className="final-text">је завршена 01.08.2024. године.</div>
          </div>
          
        </div>}

      {ageGate !== "true" &&
        <div className="soonStarting">
          <AgeGate handleAgeGate={handleAgeGate} />
        </div>}


      <MainPage />
      <Header />
    </div>
  );
}

export default App;
